import { Flex } from '@chakra-ui/react';
import AppTitle from 'common/AppTitle';
import Loader from 'common/Loader';
import Error500 from 'components/Errors/Error500';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import useCampaignId from 'data/useCampaignId';
import useConfig from 'data/useConfig';
import useCurrentLocale from 'data/useCurrentLocale';
import useSession from 'data/useSession';
import useUserAgent from 'data/useUserAgent';
import env from 'env';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { cobrowse, getFeedback, gtm } from 'trackers';
import { updateHeaders } from 'utils/axios';
import Interceptor from 'utils/axios/Interceptor';
import { setDateLocale } from 'utils/dateLocale';
import pickLocale from 'utils/pickLocale';
import { getFullPath } from 'utils/router';

interface LayoutProps {
  children: ReactNode;
  setInterval: (interval: number) => void;
}

const hideContractSwitcher: Record<string, boolean> = {
  '/': true,
  '/403': true,
  '/404': true,
  '/500': true,
  '/affiliation': true,
  '/invitation': true,
  '/support/[[...slug]]': true,
  '/contact-us': true,
};

const Layout = ({ children, setInterval }: LayoutProps) => {
  const userAgent = useUserAgent();
  const { pathname: activeRoute, query } = useRouter();
  const [hasRequiredVariables, setHasRequiredVariables] = useState<boolean | null>(null);
  const showContractSwitcher = !Boolean(hideContractSwitcher[activeRoute]);
  const { value: currentLocale, setValue: setCurrentLocale } = useCurrentLocale();
  const { setValue: setCampaignId } = useCampaignId();
  const {
    appTitle,
    availableLanguages,
    cobrowsing,
    defaultLanguage,
    isoCode,
    isLoading,
    isError,
    isOK,
    getFeedbackId,
    gtmId,
    providerId,
  } = useConfig();
  const maybeGetFeedbackId = getFeedbackId?.[currentLocale || defaultLanguage];
  const [session] = useSession();
  const userId = session?.user?.id;
  const campaignId = useMemo(
    () => Object.keys(query).find((key) => !query[key]), // ?campaign <-> { campaign: '' }
    [query],
  );

  useEffect(() => {
    if (isOK && !availableLanguages.includes(currentLocale)) {
      setCurrentLocale(pickLocale(availableLanguages, defaultLanguage));
    }
  }, [availableLanguages, defaultLanguage, isOK, currentLocale, setCurrentLocale]);

  useEffect(() => {
    updateHeaders(undefined, undefined, currentLocale);
    setDateLocale(currentLocale);
    if (document !== undefined) {
      document.documentElement.lang = currentLocale?.split('-')?.[0];
    }
  }, [currentLocale]);

  useEffect(() => {
    // access token is refreshed before 30 minutes pass, here we ask for it after refresh
    if (session?.accessTokenExpiry) {
      const secondsRemaining = Math.round(
        (session.accessTokenExpiry - env.nextAuthRefreshWindow / 2 - Date.now()) / 1000,
      );
      setInterval(secondsRemaining > 0 ? secondsRemaining : 0);
    }
  }, [session?.accessTokenExpiry, setInterval]);

  useEffect(() => getFeedback.reload(maybeGetFeedbackId), [maybeGetFeedbackId]);

  useEffect(() => {
    setHasRequiredVariables(
      gtm.setVariables({
        site_env: env.env && env.env !== 'production' ? 'preprod' : 'production',
        site_country: isoCode,
        site_type: 'Merchant Portal NGM',
        site_sourcepage: getFullPath(),
        site_ciam_id: userId,
        site_lang: currentLocale,
        site_platform: userAgent.device,
        site_browser: userAgent.browser,
        site_resolution: userAgent.resolution,
      }),
    );
  }, [
    activeRoute,
    currentLocale,
    isoCode,
    setHasRequiredVariables,
    userAgent.browser,
    userAgent.device,
    userAgent.resolution,
    userId,
  ]);

  useEffect(() => {
    if (campaignId) setCampaignId(campaignId);
  }, [campaignId, setCampaignId]);

  return (
    <Interceptor currentLocale={currentLocale} providerId={providerId}>
      {cobrowse.init(cobrowsing)}
      {gtm.init(gtmId, isoCode, hasRequiredVariables)}

      <AppTitle title={appTitle} />
      <Flex id="pluxee" direction="column" minHeight="100vh" width="100%">
        {isLoading && <Loader />}
        {isError && <Error500 />}
        {isOK && (
          <>
            {getFeedback.init(maybeGetFeedbackId)}
            <Navbar
              countryCode={isoCode}
              availableLanguages={availableLanguages}
              currentLocale={currentLocale || defaultLanguage}
              session={session}
              setCurrentLocale={setCurrentLocale}
              showContractSwitcher={showContractSwitcher}
            />
            {children}
          </>
        )}
        <Footer
          currentLocale={currentLocale || defaultLanguage}
          hasCobrowsing={Boolean(cobrowsing)}
        />
      </Flex>
    </Interceptor>
  );
};

export default Layout;
